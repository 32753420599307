<template>
  <hcc-card class="custom-cell" color="white">
    <hcc-textarea
      :label="title"
      :cols="30"
      :rows="5"
      :value="value"
      :placeholder="$t('configuration.messages.placeholder')"
      :name="title"
      @input="changeData"
      class="textarea"
    />
    <div class="paragraph">
      {{ description }}
    </div>
  </hcc-card>
</template>

<script>
import HccTextarea from '@/components/shared/HccTextarea/index.vue';

export default {
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccTextarea,
  },
  props: {
    value: {
      required: true,
    },
    title: {
      required: true,
    },
    description: {
      required: true,
    },

  },
  methods: {
    changeData(value) {
      this.$emit('update', value);
    },
  },

};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_messages-tab.scss";
</style>
